@import url(https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "Ubuntu Mono", monospace;
  font-weight: 400;
}

html {
  margin: 0;
  padding: 0;
  background: url(/static/media/bg.5eb9fda8.jpg) no-repeat center center fixed;
  background-size: cover;
  animation: 1s ease-in-out slide_in;
}

main {
  width: 100vw;
  height: 33vh;
}

@media only screen and (max-width: 700px) {
  main {
    height: 50vh;
  }
}

header > a {
  position: absolute;
  top: 1em;
  right: 1em;
  box-shadow: 0px 0px 0px #8fd3f4;
  border-radius: 50%;
}

header > a:hover {
  box-shadow: 0px 0px 20px #0ba6f3;
  transition: box-shadow 0.25s ease-in;
}

header > a > img {
  width: 50px;
  height: 50px;
}

section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
}

section > h1 {
  font-size: 4em;
}

@media only screen and (max-width: 700px) {
  section > h1 {
    font-size: 2em;
  }
}

section > h1 > em {
  font-style: normal;
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
  padding: 0.25em 0.5em 0.25em 0.5em;
}

section > h1 > em:hover {
  background-size: 100% 88%;
}

section > h1 > em > a {
  color: black;
  text-decoration: none;
  font-weight: 700;
}

section > h1 > em > a > .at_symbol {
  color: rgb(247, 55, 21);
}

.magical_underline {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
  padding: 0.25em 0.5em 0.25em 0.5em;
}

@keyframes slide_in {
  from {
    background-position-y: 75%;
  }
  to {
    background-position-y: 50%;
  }
}

